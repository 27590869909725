.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4%;
  margin: 100px 0;
}

.heading {
  font-size: 1.125rem;
  font-weight: 700;
  font-family: 'futura-pt', sans-serif;
  color: var(--s-color);
}

.aboutCard {
  text-align: center;
}

.button {
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-family: 'futura-pt', sans-serif;
  color: var(--p-color);
  margin-top: 2.5rem;
}

.AboutCards {
  display: flex;
  flex-direction: column;
  padding: 0 0 3.75rem 0;
}

@media only screen and (min-width: 768px) {
  .aboutCard {
    width: 40%;
  }

  .AboutCards {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 992px) {
  .Button {
    margin-top: 50px;
  }

  .aboutCard {
    max-width: 20%;
  }

  .AboutCards {
    flex-wrap: nowrap;
    max-width: 1200px;
  }
}
