.navigationItem {
  font-family: 'futura-pt', sans-serif;
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 12px 0px;
}

.navigationItem a {
  color: #ffffff;
  transition-timing-function: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.navigationItem a:hover {
  color: var(--p-color);
}

@media only screen and (min-width: 992px) {
  .navigationItem {
    margin: 0 10px;
  }
}
