.Toolbar {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 12px 6%;
}

.logo {
  height: 45px;
  width: 100px;
}

.DesktopOnly {
  display: none;
}

@media only screen and (min-width: 992px) {
  .Toolbar {
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 15%;
    padding-top: 25px;
  }
  .logo {
    height: 127px;
    width: 260px;
    margin-top: -32px;
    image-rendering: -webkit-optimize-contrast;
  }
  .Toolbar {
    flex-direction: row;
    align-items: center;
    padding: 20px 12% 0 12%;
  }
  .DesktopOnly {
    display: block;
  }
 .MobileOnly {
    display: none;
  } 
}


