.banner {
  margin-top: 65px;
  position: relative;
  width: 100vw;
  height: 50vh;
}

.img {
  height: 100%;
}

.bannerTitle {
  position: absolute;
  top: 50%;
  margin: 0px;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  /* font-size: 32px; */
  color: white;
  text-align: center;
}

.bannerMainTitle {
  font-size: 3rem;
  font-family: serif;
}

.bannerSubTitle {
  display: none;
}

.leftImage {
  display: inline-flex;
  align-items: center;
}

.banner::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.body {
  padding-left: 4%;
  padding-right: 4%;
  color: var(--s-color);
  font-family: 'futura-pt', sans-serif;
}

.values {
  padding-bottom: 50px;
}

.cards {
  text-align: center;
}

.card {
  display: inline-flex;
  margin: 7px 30px 7px 20px;
}

.card > div:first-child > img {
  width: 80px;
}

.icons {
  font-size: 100px;
  flex: 1;
}

.card_body {
  margin-left: 15px;
}

.card_body > h4 {
  text-align: left;
}

.card_body > p {
  text-align: left;
}

.fun_heading {
  font-size: 28px;
  line-height: 2.5rem;
}

.fun_card {
  border: 1px solid #e3e4e6;
  margin-bottom: 25px;
}

.fun_card > img {
  vertical-align: bottom;
  image-rendering: -webkit-optimize-contrast;
}

.fun_card_title {
  padding: 1.25rem;
  font-family: 'proxima-nova', sans-serif;
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 2px;
  color: #919daa;
}

@media only screen and (min-width: 992px) {
  .body {
    margin: 50px auto 20px auto;
    max-width: 1200px;
  }
  .banner {
    margin-top: 100px;
    height: 90vh;
  }
  .bannerMainTitle {
    font-size: 3rem;
    font-family: serif;
  }
  .bannerSubTitle {
    display: block;
    font-size: 18px;
    font-weight: 400;
  }
  .banner::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .card {
    margin: 10px 15px
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .fun {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 5px;
  }
  .fun_heading {
    font-size: 32px;
  }
  .fun_card {
    height: 450px;
    overflow: hidden;
  }
  .fun_card > img {
    width: auto;
    height: 88%;
    min-width: 100%;
  }
  .fun_card1 {
    width: 66%;
  }
  .fun_card2 {
    width: 33%;
  }
  .fun_card3 {
    width: 33%;
  }
  .fun_card4 {
    width: 66%;
  }
  .fun_card5 {
    width: 100%;
  }
  .fun_card6 {
    width: 66%;
  }
  .fun_card7 {
    width: 33%;
  }
  .fun_card8 {
    width: 33%;
  }
  .fun_card9 {
    width: 66%;
  }
}
