.body {
  padding-left: 4%;
  padding-right: 4%;
  color: var(--s-color);
  font-family: 'futura-pt', sans-serif;
}

.openingText {
  margin-top: 70px;
}

@media only screen and (min-width: 992px) {
  .body {
    margin: 50px auto 20px auto;
    max-width: 1200px;
  }
  .openingText {
    margin-top: 120px;
  }
}
