.banner {
  margin-top: 65px;
  position: relative;
  width: 100vw;
  height: 50vh;
  /* width: fit-content; */
}

.img {
  width: auto;
  height: 100%;
}

.bannerTitle {
  position: absolute;
  top: 50%;
  margin: 0px;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  /* font-size: 32px; */
  color: white;
  text-align: center;
}

.bannerMainTitle {
  font-size: 3rem;
  font-family: serif;
}

.bannerSubTitle {
  display: none;
}

.container_right {
  border-top: 1px solid gainsboro;
}

.banner::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.body {
  padding-left: 4%;
  padding-right: 4%;
  color: var(--s-color);
  font-family: 'futura-pt', sans-serif;
}

.dot {
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #808080;
  border-radius: 50%;
  margin-bottom: 2px;
  transform: translateX(2px);
  margin-right: 10px;
}

.filters > div {
  margin-bottom: 10px;
}

.locations {
  margin-bottom: 60px;
}

.job_card {
  color: gray;
  font-size: 0.9rem;
  padding: 10px 20px;
  transition: all .3s ease-in;
  text-align: center;
}

.sidebar_item_title {
  cursor: pointer;
  text-decoration: underline;
}

.job_location {
  display: flex;
  justify-content: flex-start;
  max-width: 300px;
  margin-bottom: 8px;
  /* border-bottom: 1px solid gainsboro; */
  padding-bottom: 5px;
}

.job_department {
  display: flex;
  justify-content: flex-start;
  max-width: 300px;
  margin-bottom: 8px;
  margin-bottom: 8px;
  /* border-bottom: 1px solid gainsboro; */
  padding-bottom: 5px;
}

.job_type {
  display: flex;
  justify-content: flex-start;
  max-width: 300px;
  margin-bottom: 8px;
  margin-bottom: 8px;
  /* border-bottom: 1px solid gainsboro; */
  padding-bottom: 5px;
}

.tick {
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.activeTitle {
  background: rgb(164, 214, 232, 0.6);
}

.filterButton {
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px 20px;
  color: grey;
  text-align: center;
}

.filterButtonGroup {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.filterHighlight {
  position: absolute;
  content: '';
  bottom: 0;
  border-top: 3px #1a73e8 solid;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.filterButton:hover {
  cursor: pointer;
}

.filterButtonActive {
  color: #24a3d8;
}

.badgeFilterButton {
  color: white;
  background-color: #de702c;
}

.badge {  
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.selectedJob {
  display: block !important;
  -webkit-animation: fadeinout 0.5s linear forwards;
  animation: fadeinout 0.5s linear forwards;
}

@-webkit-keyframes fadeinout {
  100% { opacity: 1; }
  0% { opacity: 0.2; }
}

@keyframes fadeinout {
  100% { opacity: 1; }
  0% { opacity: 0.2; }
}

@media only screen and (min-width: 768px) {
  .container {
    display: flex;
  }

  .listing {
    flex: 1 1 35%;
    height: 85vh;
    display: table;
    top: 120px;
  }

  .container_right {
    display: block;
    flex: 1 1 65%;
    white-space: break-spaces;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid gainsboro;
    border-top: none;
    /* scrollbar-width: none; */
  }
  .container_right p {
    text-align: justify;
  }

  .job_card {
    text-align: right;
  }

}

@media only screen and (min-width: 992px) {
  .img {
    width: 100%;
    height: 100%;
  }

  .body {
    margin: 50px auto 20px auto;
    max-width: 1400px;
  }
  .banner {
    margin-top: 100px;
    height: 90vh;
  }
  .bannerMainTitle {
    font-family: serif;
  }
  .bannerSubTitle {
    display: block;
    font-size: 18px;
    font-weight: 400;
  }

  .banner::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .filters {
    display: flex;
  }
  .filters > div {
    margin-right: 10px;
  }

  .sidebar_item_title {
    font-size: 20px !important;
    margin: 10px 0;
  }

  .job_card > h3 {
    font-size: 24px;
  }
  .job_details_container {
    display: none;
  }
  /* .container_right > div > div::-webkit-scrollbar {
    display: none;
  } */
}
