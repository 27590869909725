.img {
  height: 100%;
}

.cards {
  margin-bottom: 50px;
  display: inline-flex;
  flex-flow: column wrap;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.card {
  position: relative;
  width: 80%;
  margin-bottom: 20px;
}

.card > img {
  vertical-align: bottom;
  border-radius: 10px;
}

.imageFilter {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  border-radius: 10px;
}

.addressDetail {
  position: absolute;
  bottom: 0px;
  color: white;
  width: 100%;
  text-align: center;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .card {
    position: relative;
    width: 40%;
  }
  .cards {
    display: flex;
    flex-flow: row wrap;
  }
  .card > img {
    height: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .card {
    width: 30%;
  }
}
