.Header {
  position: fixed;
  width: 100%;
  height: 65px;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 992px) {
  .Header {
    height: 115px;
  }
}
