.App {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

:root {
  --p-color: #24a3d8;
  --s-color: #233b56;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

.para {
  font-size: 1.1rem;
  font-family: 'proxima nove', sans-serif;
  line-height: 1.8rem;
  font-weight: 400;
}

.heading {
  text-transform: capitalize;
  color: var(--p-color);
}

.sub-heading {
  text-transform: capitalize;
  color: var(--p-color);
}

.underline-light {
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.underline-light::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  background-color: var(--p-color);
  transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scaleX(0);
  visibility: hidden;
}

.underline-dark {
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.underline-light:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.underline-dark::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: var(--s-color);
  transition: all 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scaleX(0);
  visibility: hidden;
}

.underline-dark:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.btn {
  padding: 8px;
  border: none;
  background: var(--p-color);
  color: white;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.btn:focus {
  border: 1px solid red;
}

::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 5px;
  opacity: 0;
}
::-webkit-scrollbar {
  width: 5px;
}
