.banner {
  position: relative;
  width: 100vw;
  height: auto;
  overflow: hidden;
  background: #7474BF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #348AC7, #7474BF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #348AC7, #7474BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bannerMediaWidth {
  width: 100%;
  height: auto;
  top: 50%;
}

.bannerMediaHeight {
  width: auto;
  height: 100%;
  left: 50%;
}

.bannerVideo {
  object-fit: cover;
  display: none;
}

.bannerImage {
  display: none;
}

.bannerTitle {
  position: absolute;
  top: 50%;
  margin: 0px;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  color: white;
  text-align: center;
}

.bannerMainTitle {
  display: none;
  font-family: Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
}

.bannerSubTitle {
  display: none;
}

.banner::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.bannerSmallImage {
    display: block;
}

@media only screen and (min-width: 768px) {
  .bannerMainTitle {
    display: block;
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 992px) {
  .banner {
    height: 100vh;
    width: auto;
  }
  .bannerMediaHeight {
    height: 100%;
    width: auto;
    left: 50%;
  }
  .bannerMediaWidth {
    height: auto;
    width: 100%;
    top: 50%;
  }
  .bannerMainTitle {
    font-size: 3rem;
  }
  .bannerSubTitle {
    display: block;
    font-size: 1rem;
  }
  .banner::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .bannerVideo {
    display: block;
  }
  .bannerImage {
    display: block;
  }
  .bannerSmallImage {
    display: none;
  }
}
