.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

/* @media only screen and (min-width: 600px) {
  .NavigationItems {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
  }
} */

@media only screen and (min-width: 992px) {
  .NavigationItems {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-left: -20px;
  }
}
